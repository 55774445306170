import { axiosInstance } from "../utils/axiosHelper";

export const getAllSubCollectionsService = async (slug) => {
  return await axiosInstance
    .get(`cardCollectionSubTitles?cardCollectionTitleSlug=${slug}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const getAllChapterSlug = async (slug) => {
  return await axiosInstance
    .get(`user/chapters?cardCollectionSubTitleSlug=${slug}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};

export const getAllchapterService = async (collection, subcollection) => {
  
  return await axiosInstance
    .get(
      `chapters?cardCollectionTitleSlug=${collection}&cardCollectionSubTitleSlug=${subcollection}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
export const getAllCards = async (slug, userId) => {
  return await axiosInstance
    .get(
      `user/cardCollabrate?chapterSlug=${slug}&user_id=${userId}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
};
